
import Navbar from '@/components/Navbar/NavbarPure'
import WalletBtn from "@/containers/StakeMiner/WalletBtn";
import ethService from "@/services/eth.js";
import jwt from "jsonwebtoken";
export default {
  components: {
    WalletBtn,
    Navbar
  },
  computed: {
    showList() {
      return this.choiceList.slice(
        (this.currentPage - 1) * this.pageSize,
        this.currentPage * this.pageSize
      );
    },
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      lang: {
        cn: {
          option1: '选项A) 新的释放规则',
          option2: '选项B) 退币',
          submit: '提交我的选择',
          confirmSubmit: '提交之后将无法更改，您确定吗？',
          lockNum: '您的锁仓数量'
        },
        en: {
          option1: 'Option A) Revesting',
          option2: 'Option B) Refund',
          submit: 'Submit',
          confirmSubmit: 'After submitting, it cannot be changed. Are you sure?',
          lockNum: 'Your DPR locked'
        },
      },
      pageLoading: false,
      ethAccount: "",
      nonce: "",
      signMsg: "",
      choice: "",
      haveChoose: true,
      dprBalance: null,
      loginAccount: "",
      submitLoading: false,
      lockNum: 'loading...'
    };
  },
  async mounted() {
    this.pageLoading = true;
    this.getLoginAccount();
    await ethService.init().catch((e) => {
      console.log(e);
    });
    await this.initPage();
    this.pageLoading = false;
  },
  methods: {
    getChooseWord(val) {
      if (val == "1") {
        return this.lang[this.$$i18n.locale].agree;
      }
      if (val == "2") {
        return this.lang[this.$$i18n.locale].disagree;
      }
    },
    getLoginAccount() {
      let tokenData = window.localStorage.getItem("ETHTOKENDATA")
        ? JSON.parse(window.localStorage.getItem("ETHTOKENDATA"))
        : null;
      if (tokenData) {
        this.loginAccount = tokenData.user;
      }
    },
    async initPage() {
      await this.getEthAccount();
      if (!this.ethAccount) {
        return;
      }
      let tokenData = window.localStorage.getItem("ETHTOKENDATA")
        ? JSON.parse(window.localStorage.getItem("ETHTOKENDATA"))
        : null;
      console.log('initPage')
      if (
        !tokenData ||
        tokenData.exp * 1000 < Date.now() - 10 * 60 * 1000 ||
        this.ethAccount.toLowerCase() != tokenData.user.toLowerCase()
      ) {
        window.localStorage.clear()
        this.$toast.loading({
          message: this.lang[this.$$i18n.locale].loginTip,
          duration: 0,
        });
        try {
          await this.getNonce();
          await this.sign();
          await this.login();
        } catch (error) {
        } finally {
          this.$toast.clear();
        }
      }

      await this.getData();
      await this.getLock()
    },
    chooseItem(value) {
      if (this.haveChoose) {
        return;
      }
      this.choice = value;
    },
    submit() {
      this.$dialog.confirm({
        message: this.lang[this.$$i18n.locale].confirmSubmit,
      })
        .then(() => {
          this.submitLoading = true;
          return this.$fetch({
            headers: {
              Authorization: window.localStorage.getItem("ETHTOKEN"),
            },
            url: "/eth/choose",
            method: "POST",
            data: {
              choice: this.choice,
            },
          })
            .then((res) => {
              this.getData();
              this.$toast.success();
            })
            .finally(() => {
              this.submitLoading = false;
            });
        })
        .catch(() => {
          // on cancel
        });
    },
    login() {
      console.log('login')
      return this.$fetch({
        url: "/eth/login",
        method: "POST",
        data: {
          account: this.ethAccount,
          signMsg: this.signMsg,
        },
      }).then((res) => {
        console.log(res);
        if (res.token) {
          window.localStorage.setItem("ETHTOKEN", res.token);
          let tokenData = jwt.decode(res.token);
          window.localStorage.setItem(
            "ETHTOKENDATA",
            JSON.stringify(tokenData)
          );
          this.getLoginAccount();
        }
      });
    },
    getNonce() {
      return this.$fetch({
        url: "/eth/nonce",
        params: {
          account: this.ethAccount,
        },
      }).then((res) => {
        console.log('nonce')
        console.log(res.data)
        if (res.data) {
          this.nonce = res.data;
        } else {
          window.localStorage.clear();
          window.location.reload()
        }

      });
    },
    async getEthAccount() {
      let accounts = await web3.eth.getAccounts();
      this.ethAccount = accounts[0] || "";
      console.log(accounts);
    },
    async sign() {
      let signMsg = await window.web3.eth.personal.sign(
        this.nonce,
        this.ethAccount
      );
      console.log(signMsg);
      this.signMsg = signMsg;
    },
    getData() {
      let token = window.localStorage.getItem("ETHTOKEN")
      if (!token) {
        return
      }
      return this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("ETHTOKEN"),
        },
        url: "/eth/choose",
      }).then((res) => {
        this.choice = res.data.choice || "1";
        if (this.choice) {
          this.haveChoose = true;
        }
      }).catch(e => {
        window.localStorage.clear()
      })
    },
    getLock() {
      let token = window.localStorage.getItem("ETHTOKEN")
      if (!token) {
        return
      }
      return this.$fetch({
        headers: {
          Authorization: window.localStorage.getItem("ETHTOKEN"),
        },
        url: "/eth/lock",
      }).then((res) => {
        console.log(res)
        this.lockNum = res.data.lock || 0
      })
    },
  },
};
